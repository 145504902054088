<script setup>
import Swal from "sweetalert2";
import { useStore } from "vuex";
const store = useStore();
import getCookie from "@/scripts/utils/getCookie.js";
import loadercomp from "@/components/loadercomp";
const togglePlan = (sub) => {
    if (sub.eligible) {
        Swal.fire({
            title: `Subscribe to ${sub.name}`,
            buttonsStyling: false,
            html: `${sub.billing_description}`,
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed) {
                store.state.loader = true;
                fetch(`/api/org/${store.state.active_org.id}/subs/${sub.id}`, {
                    method: "POST",
                    headers: {
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                }).then((res) => {
                    store.state.loader = false;
                    if (res.ok) {
                        window.location.reload();
                        if (typeof window.dataLayer == "object") {
                            window.dataLayer.push({ event: "subscribe-plan" });
                        }
                        store.state.all_subscriptions[sub.id].cancelled = false;
                        store.state.all_subscriptions[sub.id].subscribed = true;
                        store.state.all_subscriptions[sub.id].expires = undefined;
                    } else {
                        console.log(res);
                        Swal.fire({
                            icon: "info",
                            text: "You are not eligible for this plan",
                            confirmButtonColor: "#417dd8",
                        });
                    }
                });
            }
        });
    } else {
        Swal.fire({
            title: `Cancel ${sub.name} plan`,
            html: `${sub.cancellation_description}`,
            showCancelButton: true,
            cancelButtonText: "Back",
            confirmButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                store.state.loader = true;
                fetch(`/api/org/${store.state.active_org.id}/subs/${sub.id}`, {
                    method: "DELETE",
                    headers: {
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                }).then((res) => {
                    store.state.loader = false;
                    if (res.ok) {
                        store.state.all_subscriptions[sub.id].cancelled = true;
                        store.state.all_subscriptions[sub.id].expires = res.expires;
                        window.location.reload();
                    } else {
                        console.log(res);
                        Swal.fire({
                            title: "Error",
                            text: "Something went wrong",
                            icon: "error",
                            showConfirmButton: false,
                        });
                    }
                });
            }
        });
    }
};

const nullButton = (a) => {
    if (a) {
        Swal.fire({
            text: "Plan cannot be cancelled",
            confirmButtonColor: "#417dd8",
            icon: "info",
        });
    } else {
        Swal.fire({
            icon: "info",
            text: "You are not eligible for this plan",
            confirmButtonColor: "#417dd8",
        });
    }
};
</script>

<template>
    <div class="dashboard_root">
        <loadercomp v-if="$store.state.loader" />
        <section class="flex_col">
            <div class="overview">
                <h2>Overview</h2>
                <br />
                <div class="plan_info flex_col">
                    <p>Usage: {{ $store.state.usage }}</p>
                    <p>Days Left in Cycle: {{ $store.state.days_left_in_cycle }}</p>
                    <p>Accrued Charges: ${{ $store.state.metered_cost }}</p>
                    <router-link
                        v-if="$store.state.active_org"
                        class="flex"
                        :to="{ path: '/buy-credits', query: { org: `${$store.state.active_org.id}` } }"
                    >
                        <img src="@/assets/buy_requests.svg" alt="" />&nbsp;&nbsp;&nbsp;
                        <p>Buy Request Credits</p>
                    </router-link>
                    <router-link
                        v-if="$store.state.active_org"
                        class="flex"
                        :to="{ name: 'Paymethod', query: { org: `${$store.state.active_org.id}` } }"
                    >
                        <img src="@/assets/credit-card.svg" alt="" />&nbsp;&nbsp;&nbsp;
                        <p v-if="$store.state.payment === 'Not on file'">Add Credit Card</p>
                        <p v-else>Update Credit Card on File</p>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="plans">
                    <div class="plan_card flex_col" v-for="sub in $store.state.subscribed" :key="sub.id">
                        <div class="togglePlan">
                            <img @click="nullButton(true)" v-if="!sub.cancellable" src="@/assets/to_nullcancel.svg" alt="" />
                            <img @click="togglePlan(sub)" v-else src="@/assets/to_subscribed.svg" alt="" />
                        </div>
                        <div class="txt flex_col">
                            <h3>{{ sub.name }}</h3>
                            <p v-html="sub.description"></p>
                        </div>
                        <div v-if="sub.permissions.length">
                            <h4>Permissions</h4>
                            <ul>
                                <li v-for="item in sub.permissions" :key="item">
                                    {{ item.name }}
                                    : <i>{{ item.description }}</i>
                                </li>
                            </ul>
                        </div>

                        <div v-if="sub.expires">
                            <p>
                                Expiration Date:
                                <span>{{ new Date(sub.expires * 1000).toLocaleDateString() }}</span>
                            </p>
                        </div>

                        <div v-if="sub.limit > 0">
                            <p>Requests</p>
                            <p v-if="sub.limit < 9000000000000000000">{{ sub.limit.toLocaleString() }}</p>
                            <p v-else>&infin;</p>
                        </div>
                    </div>

                    <div class="plan_card flex_col" v-for="sub in $store.state.unsubscribed" :key="sub">
                        <div class="togglePlan">
                            <img @click="nullButton(false)" v-if="!sub.eligible" src="@/assets/to_ineligble.svg" alt="" />
                            <img @click="togglePlan(sub)" v-else src="@/assets/to_unsubscribed.svg" alt="" />
                        </div>
                        <div class="txt flex_col">
                            <h3>{{ sub.name }}</h3>
                            <p v-html="sub.description"></p>
                        </div>
                        <div v-if="sub.permissions.length">
                            <h4>Permissions</h4>
                            <ul>
                                <li v-for="item in sub.permissions" :key="item">
                                    <span class="highlight">{{ item.name }}</span
                                    >: <i>{{ item.description }}</i>
                                </li>
                            </ul>
                        </div>
                        <div v-if="sub.limit > 0">
                            <h4>Requests</h4>
                            <p v-if="sub.limit < 9000000000000000000">{{ sub.limit.toLocaleString() }}</p>
                            <p v-else>&infin;</p>
                        </div>
                        <div :data-key="sub.name" v-if="!sub.eligible">
                            <h4>Eligibility</h4>
                            <p v-if="sub.requirement == 'CARD'">Add a Credit Card</p>
                            <p v-else-if="sub.requirement == 'EMAIL'">Requires a confirmed email address</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
h2 {
    color: $offblack;
    padding: 0px;
}
h3 {
    color: $offblack;
    font-size: 1.6em;
    font-family: "Monument";
}
h4 {
    text-decoration: underline;
}
section {
    gap: 4rem;
    .overview {
        .plan_info {
            gap: 1rem;
            img {
                @extend %nodrag;
            }
            a {
                text-decoration: none;
                color: $blue;
            }
        }
    }
    .plans {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem 2.5rem;
        @include media("<=tablet") {
            grid-template-columns: repeat(1, 1fr);
        }
        .plan_card {
            padding: 2rem;
            border-radius: 10px;
            box-shadow: 1px 1px 14px 2px #e4e4e4;
            gap: 1rem;
            @include media("<=phone-tab") {
                padding: 1rem;
            }
            .togglePlan {
                img {
                    width: 4.5rem;
                    @extend %nodrag;
                    transition: transform 0.1s ease-out;
                    cursor: pointer;
                    &:hover {
                        transform: scale(0.98);
                    }
                }
            }
            .txt {
                gap: 1rem;
            }
            .details_ {
                gap: 2rem;
            }
            ul {
                gap: 0.6rem;
                display: flex;
                flex-direction: column;
                li {
                    list-style: none;
                }
            }
        }
    }
}
</style>
